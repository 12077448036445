import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./firebase";
import { collection, query, orderBy, limit, getDocs } from "firebase/firestore";
import AuditLogPopup from "./components/AuditLogPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBook, faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import "./stylesheets/dashboard.css";

library.add(faBook, faRightToBracket);

const Dashboard = () => {
  const [lastUpdater, setLastUpdater] = useState({
    timestamp: null,
    email: "",
    action: "",
  });
  const [isAuditLogVisible, setIsAuditLogVisible] = useState(false);
  const [user, setUser] = useState(null);
  const [isGirilangUser, setIsGirilangUser] = useState(false); // Track if user is in GirilangUsers
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLastUpdater = async () => {
      const auditCollection = collection(db, "AuditListJankov");
      const q = query(auditCollection, orderBy("timestamp", "desc"), limit(1));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const lastDoc = querySnapshot.docs[0];
        setLastUpdater(lastDoc.data());
      }
    };

    fetchLastUpdater();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        checkGirilangUser(currentUser.email); // Check if the user is in GirilangUsers
      } else {
        navigate("/login"); // Redirect to login if not authenticated
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // Function to check if the current user is in the GirilangUsers collection
  const checkGirilangUser = async (email) => {
    try {
      const usersCollection = collection(db, "GirilangUsers");
      const querySnapshot = await getDocs(usersCollection);

      querySnapshot.forEach((doc) => {
        if (doc.data().email === email) {
          setIsGirilangUser(true);
        }
      });
    } catch (error) {
      console.error("Error checking Girilang users:", error);
    }
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Sign out error", error);
      });
  };

  const formatDate = (date) => {
    if (!date) return "";
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  return (
    <div className="dashboard">
      <div className="header">
        <div>
          <h2>Welcome, {user ? user.displayName : "User"}</h2>
          <h1>Corona Projects Dashboard</h1>
        </div>
      </div>

      <div className="dash-priority">
        <h2>Services</h2>
        <div className="dash-priority-lists">
          {/* Alex Jankov Tile */}
          <div className="dashboard-tile">
            <h3>Alex Jankov</h3>

            <div className="last-updated">
              <p>Last Updated: {formatDate(lastUpdater.timestamp?.toDate())}</p>
            </div>
            <div className="tile-buttons">
              <button
                onClick={() => setIsAuditLogVisible(true)}
                className="audit-log-button"
              >
                Audit
                <FontAwesomeIcon icon="book" className="aduit-icon " />
              </button>

              <button
                onClick={() => navigate("/JankovList")}
                className="enter-button"
              >
                Enter
                <FontAwesomeIcon
                  className="enter-icon"
                  icon="fa-solid fa-right-to-bracket"
                  style={{ color: "#ffffff" }}
                />
              </button>
            </div>
          </div>

          {/* Girilang Developments Tile - Only Visible to Girilang Users */}
          {isGirilangUser && (
            <div className="dashboard-tile">
              <h3>Girilang Developments</h3>

              <div className="tile-buttons">
                <button
                  onClick={() => navigate("/GirilangDashboard")}
                  className="enter-button"
                >
                  Enter
                  <FontAwesomeIcon
                    className="enter-icon"
                    icon="fa-solid fa-right-to-bracket"
                    style={{ color: "#ffffff" }}
                  />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <AuditLogPopup
        isVisible={isAuditLogVisible}
        onClose={() => setIsAuditLogVisible(false)}
      />
      <button onClick={handleSignOut} className="signout-buttonDash">
        Sign Out
      </button>
    </div>
  );
};

export default Dashboard;
