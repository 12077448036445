import React from "react";
import { Droppable } from "react-beautiful-dnd";
import Task from "./Task";

const TaskList = ({ tasks, priority, onDelete, onEdit }) => {
  return (
    <Droppable droppableId={priority}>
      {(provided) => (
        <div
          className={`task-list ${priority}`}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <h2>{priority}</h2>
          {tasks.map((task, index) => (
            <Task
              key={task.id}
              task={task}
              index={index}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default TaskList;
