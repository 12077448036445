import React, { useEffect, useState } from "react";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../firebase";
import "../stylesheets/auditLogPopup.css";

const AuditLogPopup = ({ isVisible, onClose }) => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    if (!isVisible) return;

    const q = query(
      collection(db, "AuditListJankov"),
      orderBy("timestamp", "desc")
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newLogs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLogs(newLogs);
    });

    return () => unsubscribe();
  }, [isVisible]);

  const formatDate = (date) => {
    if (!date) return "";
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const filterLogs = () => {
    const now = new Date();
    const startOfToday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    const startOfLastWeek = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 7
    );

    const todayLogs = logs.filter(
      (log) => log.timestamp.toDate() >= startOfToday
    );

    const lastWeekLogs = logs.filter(
      (log) =>
        log.timestamp.toDate() < startOfToday &&
        log.timestamp.toDate() >= startOfLastWeek
    );

    return { todayLogs, lastWeekLogs };
  };

  if (!isVisible) return null;

  const { todayLogs, lastWeekLogs } = filterLogs();

  return (
    <div>
      <div className="overlay" onClick={onClose}></div>
      <div className="audit-log-popup">
        <div className="audit-log-header">
          <h2>Audit Log</h2>
          <button onClick={onClose} className="close-button">
            Close
          </button>
        </div>
        <div className="audit-log-content">
          <div className="audit-log-columns">
            <div className="audit-log-column">
              <h3>Today</h3>
              {todayLogs.length > 0 ? (
                todayLogs.map((log) => (
                  <div key={log.id} className="audit-log-entry">
                    <p>
                      <strong>{formatDate(log.timestamp.toDate())}</strong>
                    </p>
                    <p>
                      <strong>User:</strong> {log.email}
                    </p>
                    <p>
                      <strong>Action:</strong> {log.action}
                    </p>
                    <p>
                      <strong>Details:</strong> {log.details}
                    </p>
                  </div>
                ))
              ) : (
                <p>No entries today.</p>
              )}
            </div>

            <div className="audit-log-column">
              <h3>Last Week</h3>
              {lastWeekLogs.length > 0 ? (
                lastWeekLogs.map((log) => (
                  <div key={log.id} className="audit-log-entry">
                    <p>
                      <strong>{formatDate(log.timestamp.toDate())}</strong>
                    </p>
                    <p>
                      <strong>User:</strong> {log.email}
                    </p>
                    <p>
                      <strong>Action:</strong> {log.action}
                    </p>
                    <p>
                      <strong>Details:</strong> {log.details}
                    </p>
                  </div>
                ))
              ) : (
                <p>No entries from the last week.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditLogPopup;
