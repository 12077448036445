import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  getAuth,
  GoogleAuthProvider,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBTNkdTcOC79Vt2qrKEbwtXT-FIbz0GVJA",
  authDomain: "taskmanagercp.firebaseapp.com",
  projectId: "taskmanagercp",
  storageBucket: "taskmanagercp.appspot.com",
  messagingSenderId: "21665258426",
  appId: "1:21665258426:web:4779ea8f198b8790851b83",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Set persistence to local (default behavior)
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Persistence has been set
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

export { db, auth, googleProvider };
