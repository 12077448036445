import React, { useState } from "react";
import emailjs from "emailjs-com";

const EmailForm = ({ tasks }) => {
  const [emails, setEmails] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);

  const suggestedEmails = [
    "info@ajdstudio.com.au",
    "coronaprojects@gmail.com",
    "team@coronaprojects.com.au",
  ];

  const addSuggestedEmail = (email) => {
    setEmails((prevEmails) => {
      const emailList = prevEmails
        .split(",")
        .map((e) => e.trim())
        .filter((e) => e);
      if (!emailList.includes(email)) {
        return emailList.length > 0 ? `${prevEmails}, ${email}` : email;
      }
      return prevEmails;
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Filter tasks to include only those with "High" or "Normal" priority, excluding "On Hold"
    const filteredTasks = tasks.filter((task) => task.priority !== "On Hold");

    // Sort tasks by priority: High first, then others
    const sortedTasks = filteredTasks.sort((a, b) => {
      if (a.priority === "High" && b.priority !== "High") return -1;
      if (a.priority !== "High" && b.priority === "High") return 1;
      return 0;
    });

    // Construct the task summary
    let taskSummary = "";
    let count = 1;

    sortedTasks.forEach((task, index) => {
      const taskPrefix =
        task.priority === "High" ? `${task.priority} Priority - ` : "";
      taskSummary += `${count}. ${taskPrefix}${task.name}: ${task.description}\n`;

      if (
        task.priority === "High" &&
        (!sortedTasks[index + 1] || sortedTasks[index + 1].priority !== "High")
      ) {
        taskSummary += `\n`; // Add a line break after the last High priority task
      }

      count++;
    });

    const today = new Date().toLocaleDateString();
    const emailContent = `Hi Alex,\n\nPlease find a summary of the priority list for ${today} below:\n\n${taskSummary}`;

    const templateParams = {
      to_email: emails,
      message: emailContent,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setPopupMessage("Email sent successfully!");
          setPopupVisible(true);
          setTimeout(() => setPopupVisible(false), 4000);
        },
        (error) => {
          console.log("FAILED...", error);
          setPopupMessage("Failed to send email.");
          setPopupVisible(true);
          setTimeout(() => setPopupVisible(false), 4000);
        }
      );

    setEmails("");
  };

  return (
    <div className="email-form-container">
      {popupVisible && <div className="popup">{popupMessage}</div>}
      <div className="suggested-emails">
        {suggestedEmails.map((email) => (
          <button key={email} onClick={() => addSuggestedEmail(email)}>
            {email}
          </button>
        ))}
      </div>
      <form className="email-form" onSubmit={sendEmail}>
        <input
          type="text"
          placeholder="Enter comma-separated emails"
          value={emails}
          onChange={(e) => setEmails(e.target.value)}
          required
        />
        <button type="submit">Send Summary</button>
      </form>
    </div>
  );
};

export default EmailForm;
