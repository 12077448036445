import React, { useState, useEffect } from "react";
import { auth, googleProvider, db } from "../firebase";
import {
  signInWithPopup,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { collection, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import "../stylesheets/login.css";

library.add(faRightToBracket);

const Login = ({ setUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        navigate("/dashboard");
      }
    });

    return () => unsubscribe();
  }, [navigate, setUser]);

  const fetchAllowlistedEmails = async () => {
    const verifiedEmailsCollection = collection(db, "verifiedemails");
    const snapshot = await getDocs(verifiedEmailsCollection);
    const emails = snapshot.docs.map((doc) => doc.data().email);
    return emails;
  };

  const isEmailAllowed = async (email) => {
    const allowlistedEmails = await fetchAllowlistedEmails();
    return allowlistedEmails.includes(email);
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const allowed = await isEmailAllowed(result.user.email);
      if (allowed) {
        setUser(result.user);
        navigate("/dashboard");
      } else {
        setError(
          "Your email is not registered in our database - Contact administrator"
        );
        await auth.signOut();
      }
    } catch (error) {
      console.error(error);
      setError(getCustomErrorMessage(error.code));
    }
  };

  const signInWithEmail = async (e) => {
    e.preventDefault();
    const allowed = await isEmailAllowed(email);
    if (allowed) {
      try {
        const result = await signInWithEmailAndPassword(auth, email, password);
        setUser(result.user);
        navigate("/dashboard");
      } catch (error) {
        console.error(error);
        setError(getCustomErrorMessage(error.code));
      }
    } else {
      setError(
        "Your email is not registered in our database - Contact administrator"
      );
    }
  };

  const getCustomErrorMessage = (errorCode) => {
    switch (errorCode) {
      case "auth/invalid-email":
        return "Invalid email address format.";
      case "auth/user-disabled":
        return "User account is disabled.";
      case "auth/user-not-found":
        return "User account not found.";
      case "auth/wrong-password":
        return "Wrong password.";
      case "auth/email-already-in-use":
        return "Email is already in use.";
      case "auth/weak-password":
        return "Password is too weak.";
      case "auth/too-many-requests":
        return "Too many requests. Please try again later.";
      case "auth/network-request-failed":
        return "Network error. Please check your connection.";
      case "auth/operation-not-allowed":
        return "This operation is not allowed.";
      default:
        return "An unexpected error occurred. Please try again.";
    }
  };

  return (
    <div className="login">
      <div className="login-logo">
        <img
          src={`${process.env.PUBLIC_URL}/LogoWebVarBW-01.svg`}
          alt="Logo"
          className="logo"
        />
      </div>

      <h2>Sign In</h2>
      <h3>Corona Projects Dashboard</h3>

      <form onSubmit={signInWithEmail}>
        <p className="login-form-headers">Email</p>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
        />
        <p className="login-form-headers">Password</p>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your password"
          required
        />
        {error && <p className="error">{error}</p>}
        <button type="submit">
          Sign in with email
          <FontAwesomeIcon
            className="login-icon"
            icon="fa-solid fa-right-to-bracket"
            style={{ color: "#ffffff" }}
          />
        </button>
        <button onClick={signInWithGoogle}>
          <img
            src={`${process.env.PUBLIC_URL}/google-brands-solid.svg`}
            alt="google-icon"
            className="google-icon"
          />
          Sign in with Google
        </button>
      </form>
    </div>
  );
};

export default Login;
