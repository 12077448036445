// AddTaskForm.js
import React, { useState } from "react";

const AddTaskForm = ({ addTask }) => {
  const [task, setTask] = useState({
    name: "",
    description: "",
    priority: "High",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    addTask(task);
    setTask({ name: "", description: "", priority: "High" });
  };

  return (
    <form className="task-form" onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Task Name"
        value={task.name}
        onChange={(e) => setTask({ ...task, name: e.target.value })}
        required
      />
      <textarea
        className="taskformtextarea"
        placeholder="Task Description"
        value={task.description}
        onChange={(e) => setTask({ ...task, description: e.target.value })}
        required
      />
      <select
        value={task.priority}
        onChange={(e) => setTask({ ...task, priority: e.target.value })}
      >
        <option value="High">High</option>
        <option value="Normal">Normal</option>
      </select>
      <button type="submit">Add Task</button>
    </form>
  );
};

export default AddTaskForm;
