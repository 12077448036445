import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";
import { db, auth } from "./firebase";
import {
  collection,
  onSnapshot,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  writeBatch,
  query,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import TaskList from "./components/TaskList";
import AddTaskForm from "./components/AddTaskForm";
import EditTaskForm from "./components/EditTaskForm";
import EmailForm from "./components/EmailForm";
import TaskPriorityChart from "./components/TaskPriorityChart";
import "./stylesheets/styles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBook, faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import "./stylesheets/dashboard.css";

library.add(faBook, faRightToBracket);

const JankovList = () => {
  const [tasks, setTasks] = useState([]);
  const [taskToEdit, setTaskToEdit] = useState(null);
  const [user, setUser] = useState(null);
  const [lastUpdater, setLastUpdater] = useState({
    timestamp: null,
    email: "",
    action: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    if (user) {
      const tasksCollection = collection(db, "JankovTasks");
      const unsubscribe = onSnapshot(tasksCollection, (snapshot) => {
        const tasksData = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          .sort((a, b) => a.index - b.index);
        setTasks(tasksData);
      });

      return () => unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    const fetchLastUpdater = async () => {
      const auditCollection = collection(db, "AuditListJankov");
      const q = query(auditCollection, orderBy("timestamp", "desc"), limit(1));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const lastDoc = querySnapshot.docs[0];
        setLastUpdater(lastDoc.data());
      }
    };

    fetchLastUpdater();
  }, [tasks]);

  const logAuditEvent = async (action, details) => {
    const auditCollection = collection(db, "AuditListJankov");
    await addDoc(auditCollection, {
      timestamp: new Date(),
      email: user.email,
      action,
      details,
    });
  };

  const addTask = async (task) => {
    const tasksCollection = collection(db, "JankovTasks");
    const tasksInPriority = tasks.filter((t) => t.priority === task.priority);
    const newIndex = tasksInPriority.length;

    await addDoc(tasksCollection, {
      ...task,
      index: newIndex,
      updatedBy: user.email,
      updatedAt: new Date(),
    });
    await logAuditEvent(
      "add",
      `Added task: "${task.description}" with priority ${task.priority}`
    );
  };

  const deleteTask = async (id) => {
    const taskDoc = doc(db, "JankovTasks", id);
    await deleteDoc(taskDoc);
    await logAuditEvent("delete", `Deleted task with id ${id}`);
  };

  const editTask = async (updatedTask) => {
    const taskDoc = doc(db, "JankovTasks", updatedTask.id);
    const oldTask = tasks.find((task) => task.id === updatedTask.id);
    let details = `Edited task with id ${updatedTask.id}. `;

    if (oldTask.description !== updatedTask.description) {
      details += `Description changed from "${oldTask.description}" to "${updatedTask.description}". `;
    }
    if (oldTask.priority !== updatedTask.priority) {
      details += `Priority changed from "${oldTask.priority}" to "${updatedTask.priority}". `;
    }

    await updateDoc(taskDoc, {
      ...updatedTask,
      updatedBy: user.email,
      updatedAt: new Date(),
    });
    setTaskToEdit(null);
    await logAuditEvent("edit", details);
  };

  const clearEdit = () => {
    setTaskToEdit(null);
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = source.droppableId;
    const finish = destination.droppableId;

    if (start === finish) {
      const newTaskOrder = Array.from(
        tasks.filter((task) => task.priority === start)
      );
      const [movedTask] = newTaskOrder.splice(source.index, 1);
      newTaskOrder.splice(destination.index, 0, movedTask);

      const updatedTasks = tasks.map((task) => {
        if (task.priority === start) {
          const index = newTaskOrder.findIndex((t) => t.id === task.id);
          return { ...task, index };
        }
        return task;
      });

      setTasks(updatedTasks);

      const batch = writeBatch(db);
      updatedTasks.forEach((task) => {
        const taskDoc = doc(db, "JankovTasks", task.id);
        batch.update(taskDoc, {
          priority: task.priority,
          index: task.index,
          updatedBy: user.email,
          updatedAt: new Date(),
        });
      });
      await batch.commit();
      await logAuditEvent(
        "drag",
        `Dragged task with id ${draggableId} within priority ${start}`
      );
    } else {
      const startTasks = Array.from(
        tasks.filter((task) => task.priority === start)
      );
      const finishTasks = Array.from(
        tasks.filter((task) => task.priority === finish)
      );

      const [movedTask] = startTasks.splice(source.index, 1);
      finishTasks.splice(destination.index, 0, movedTask);

      const updatedTasks = tasks.map((task) => {
        if (task.id === draggableId) {
          return {
            ...task,
            priority: finish,
            index: finishTasks.indexOf(task),
          };
        }

        if (task.priority === start) {
          const index = startTasks.findIndex((t) => t.id === task.id);
          return { ...task, index };
        }

        if (task.priority === finish) {
          const index = finishTasks.findIndex((t) => t.id === task.id);
          return { ...task, index };
        }

        return task;
      });

      setTasks(updatedTasks);

      const batch = writeBatch(db);
      updatedTasks.forEach((task) => {
        const taskDoc = doc(db, "JankovTasks", task.id);
        batch.update(taskDoc, {
          priority: task.priority,
          index: task.index,
          updatedBy: user.email,
          updatedAt: new Date(),
        });
      });
      await batch.commit();
      await logAuditEvent(
        "drag",
        `Dragged task with id ${draggableId} from ${start} to ${finish}`
      );
    }
  };

  const priorities = ["High", "Normal", "On Hold"];

  if (!user) {
    return null; // Login will be handled by Router
  }

  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div className="JankovList">
      <header>
        <div className="title">
          <h2>Alex Jankov's List of Priorities</h2>
          <h1>{formattedDate}</h1>
          <div className="lastupdated">
            <p>
              Last Updated by: {lastUpdater.email},{" "}
              {new Date(lastUpdater.timestamp?.toDate()).toLocaleString()}
            </p>
          </div>

          <button
            onClick={() => navigate("/dashboard")}
            className="back-button"
          >
            <FontAwesomeIcon
              icon="fa-solid fa-right-to-bracket"
              rotation={180}
              style={{ color: "#ffffff" }}
              className="back-button-icon"
            />
            Back
          </button>
        </div>
      </header>
      <div className="inline-forms">
        <div>
          <AddTaskForm addTask={addTask} />
        </div>
        <div>
          <EmailForm tasks={tasks} />
        </div>
        <div>
          <TaskPriorityChart tasks={tasks} />
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="task-lists">
          {priorities.map((priority) => (
            <TaskList
              key={priority}
              priority={priority}
              tasks={tasks.filter((task) => task.priority === priority)}
              onDelete={deleteTask}
              onEdit={setTaskToEdit}
            />
          ))}
        </div>
      </DragDropContext>
      <EditTaskForm
        editTask={editTask}
        taskToEdit={taskToEdit}
        clearEdit={clearEdit}
      />
    </div>
  );
};

export default JankovList;
