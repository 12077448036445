import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./stylesheets/index.css";
import JankovList from "./JankovList";
import Login from "./components/Login";
import Dashboard from "./Dashboard";
import GirilangDashboard from "./GirilangApp/GirilangDashboard";
import reportWebVitals from "./reportWebVitals";

const Root = () => {
  const [user, setUser] = React.useState(null);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            user ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
          }
        />
        <Route path="/login" element={<Login setUser={setUser} />} />
        <Route
          path="/dashboard"
          element={user ? <Dashboard /> : <Navigate to="/login" />}
        />
        <Route
          path="/JankovList"
          element={user ? <JankovList /> : <Navigate to="/login" />}
        />
        <Route
          path="/GirilangDashboard"
          element={user ? <GirilangDashboard /> : <Navigate to="/login" />}
        />
      </Routes>
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

reportWebVitals();
