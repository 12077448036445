// EditTaskForm.js
import React, { useState, useEffect } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const EditTaskForm = ({ editTask, taskToEdit, clearEdit }) => {
  const [task, setTask] = useState({
    name: "",
    description: "",
    priority: "High",
  });

  useEffect(() => {
    if (taskToEdit) {
      setTask(taskToEdit);
    }
  }, [taskToEdit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    editTask(task);
    clearEdit(); // Close the modal after submitting
  };

  return (
    <Modal
      isOpen={!!taskToEdit}
      onRequestClose={clearEdit}
      contentLabel="Edit Task"
      className="modal"
      overlayClassName="overlay"
    >
      <form className="task-form" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Task Name"
          value={task.name}
          onChange={(e) => setTask({ ...task, name: e.target.value })}
          required
        />
        <textarea
          placeholder="Task Description"
          value={task.description}
          onChange={(e) => setTask({ ...task, description: e.target.value })}
          required
        />
        <select
          value={task.priority}
          onChange={(e) => setTask({ ...task, priority: e.target.value })}
        >
          <option value="High">High</option>
          <option value="Normal">Normal</option>
        </select>
        <div className="edit-task">
          <button type="submit">Update Task</button>
          <button type="button" onClick={clearEdit} className="cancel-button">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default EditTaskForm;
