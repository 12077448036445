// src/components/GirilangDashboard.js

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase";
import { collection, onSnapshot, doc, deleteDoc } from "firebase/firestore";
import Sidebar from "./components/Sidebar";
import ApplicationsTable from "./components/ApplicationsTable";
import Calendar from "./components/Calendar";
import "../stylesheets/GirilangStyles.css";

const GirilangDashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [user, setUser] = useState(null);
  const [applications, setApplications] = useState([]);
  const [showApplications, setShowApplications] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false); // Manage calendar visibility

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
        setIsSidebarOpen(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "Girilang/104GardenersRD/TenantProspectiveID"),
      (snapshot) => {
        const apps = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setApplications(apps);
        setShowApplications(true);
      },
      (error) => {
        console.error("Error fetching documents: ", error);
      }
    );

    return () => unsubscribe();
  }, []);

  const resetView = () => {
    setShowCalendar(false); // Hide the calendar
    setShowApplications(false); // Optionally hide applications
  };

  const handleDashboardClick = () => {
    resetView(); // Hide calendar and other content
    navigate("/dashboard");
  };

  const handlePropertiesClick = () => {
    resetView(); // Hide calendar and other content
    // Show properties-related content if needed
  };

  const handleShowApplications = () => {
    resetView(); // Hide calendar
    setShowApplications(true); // Show applications
  };

  const handleCalendarClick = () => {
    resetView(); // Hide all other content
    setShowCalendar(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Sign out error", error);
      });
  };

  const handleArchive = async (id) => {
    try {
      await deleteDoc(
        doc(db, "Girilang/104GardenersRD/TenantProspectiveID", id)
      );
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const handleEventClick = (info) => {
    alert(`Event: ${info.event.title}`);
    // Implement further interaction logic here, e.g., navigating to event details
  };

  const handleDateClick = (arg) => {
    const title = prompt("Enter Event Title:");
    if (title) {
      // Handle adding the event to the calendar
      // This would typically involve updating your backend (Firestore) and state
      alert(`Event "${title}" added on ${arg.dateStr}`);
    }
  };

  return (
    <div className="dashboard">
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        isMobileView={isMobileView}
        closeSidebar={closeSidebar}
        handleDashboardClick={handleDashboardClick}
        handlePropertiesClick={handlePropertiesClick}
        handleShowApplications={handleShowApplications}
        handleCalendarClick={handleCalendarClick}
        handleSignOut={handleSignOut}
        resetView={resetView}
      />

      <main className="content">
        {!showCalendar && showApplications && (
          <ApplicationsTable
            applications={applications}
            handleArchive={handleArchive}
            navigate={navigate}
          />
        )}

        {showCalendar && (
          <Calendar
            handleDateClick={handleDateClick}
            handleEventClick={handleEventClick}
          />
        )}
      </main>
    </div>
  );
};

export default GirilangDashboard;
