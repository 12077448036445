import React from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";

const TaskPriorityChart = ({ tasks }) => {
  const highPriorityCount = tasks.filter(
    (task) => task.priority === "High"
  ).length;
  const normalPriorityCount = tasks.filter(
    (task) => task.priority === "Normal"
  ).length;
  const totalCount = tasks.length;

  const data = {
    labels: ["High", "Normal"],
    datasets: [
      {
        data: [highPriorityCount, normalPriorityCount],
        backgroundColor: ["#FFB300", "#33c992"],
        hoverBackgroundColor: ["#FFB300", "#33c992"],
        borderWidth: 1,
        borderRadius: 10, // Round the corners
        spacing: 6, // Add a gap between elements
      },
    ],
  };

  const options = {
    cutout: "70%",
    rotation: -90,
    circumference: 180,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          font: {
            family: "Montserrat", // Set font to Montserrat
          },
        },
        onClick: null, // Disable legend click
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
      beforeDraw: function (chart) {
        const width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        const fontSize = (height / 160).toFixed(2);
        ctx.font = `${fontSize}em Montserrat`;
        ctx.textBaseline = "middle";
        const text = `${totalCount}`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 1.3;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  };

  return (
    <div className="task-chart" style={{ marginLeft: "100px" }}>
      <div style={{ width: "250px", position: "relative" }}>
        <Doughnut data={data} options={options} />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "1em",
            fontFamily: "Montserrat, sans-serif",
            textAlign: "center",
          }}
        >
          <h3 style={{ margin: 0 }}>{totalCount} Tasks</h3>
        </div>
      </div>
    </div>
  );
};

export default TaskPriorityChart;
