import React, { useState } from "react";
import Modal from "react-modal";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, auth } from "../../firebase";

const ScheduleInspectionModal = ({ show, onClose, onSave, formData }) => {
  const [title, setTitle] = useState(`Inspection with ${formData.fullName}`);
  const [description, setDescription] = useState(
    `${formData.fullName}\n${formData.email}\n${formData.phoneNumber}\n${formData.moveDate}\n${formData.leaseDuration}\n${formData.occupation}\n${formData.pets}\n${formData.rentedBefore}\n${formData.residencyStatus}`
  );
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [location, setLocation] = useState("");
  const [allDay, setAllDay] = useState(false);

  const handleSave = async () => {
    const user = auth.currentUser;
    const startTimestamp = new Date(`${date}T${time}`);
    const eventData = {
      title,
      description,
      start: startTimestamp,
      end: startTimestamp,
      location,
      allDay,
      attendees: [formData.email],
      colour: "#2196F3",
      createdAt: serverTimestamp(),
      createdBy: user.email,
      status: "Scheduled",
      updatedAt: serverTimestamp(),
    };

    try {
      await addDoc(collection(db, "Girilang/Calendar/Events"), eventData);
      onSave(eventData);
      onClose();
    } catch (error) {
      console.error("Error saving event: ", error);
    }
  };

  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      contentLabel="Schedule Inspection"
      className="modal"
      overlayClassName="overlay"
    >
      <h2>Schedule Inspection</h2>
      <form>
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            id="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows="5"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="date">Date</label>
          <input
            id="date"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="time">Time</label>
          <input
            id="time"
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="location">Location</label>
          <input
            id="location"
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>
            <input
              type="checkbox"
              checked={allDay}
              onChange={(e) => setAllDay(e.target.checked)}
            />
            All Day
          </label>
        </div>

        <button type="button" onClick={handleSave}>
          Create Event
        </button>
        <button type="button" onClick={onClose}>
          Cancel
        </button>
      </form>
    </Modal>
  );
};

export default ScheduleInspectionModal;
