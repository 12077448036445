// src/components/Sidebar.js

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faHome,
  faBuilding,
  faUserGroup,
  faEnvelopeOpenText,
  faFolderOpen,
  faFileSignature,
  faCalendarDays,
  faMagnifyingGlass,
  faRightToBracket,
} from "@fortawesome/free-solid-svg-icons";

const Sidebar = ({
  isSidebarOpen,
  isMobileView,
  closeSidebar,
  handleDashboardClick,
  handlePropertiesClick,
  handleShowApplications,
  handleCalendarClick,
  handleSignOut,
  resetView,
}) => (
  <aside className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
    {isMobileView && (
      <button className="close-sidebar" onClick={closeSidebar}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
    )}
    <h2>Girilang Developments</h2>
    <nav>
      <ul>
        <li>
          <button onClick={handleDashboardClick}>
            <FontAwesomeIcon icon={faHome} className="sidebar-icon" /> Dashboard
          </button>
        </li>
        <li>
          <button onClick={handlePropertiesClick}>
            <FontAwesomeIcon icon={faBuilding} className="sidebar-icon" />{" "}
            Properties
          </button>
        </li>
        <li>
          <button onClick={() => resetView()}>
            <FontAwesomeIcon icon={faUserGroup} className="sidebar-icon" />{" "}
            Tenants
          </button>
        </li>
        <li>
          <button onClick={() => resetView()}>
            <FontAwesomeIcon
              icon={faEnvelopeOpenText}
              className="sidebar-icon"
            />{" "}
            Messages
          </button>
        </li>
        <li>
          <button onClick={handleShowApplications}>
            <FontAwesomeIcon icon={faFolderOpen} className="sidebar-icon" />{" "}
            Lease Applications
          </button>
        </li>
        <li>
          <button onClick={() => resetView()}>
            <FontAwesomeIcon icon={faFileSignature} className="sidebar-icon" />{" "}
            Lease Agreements
          </button>
        </li>
        <li>
          <button onClick={handleCalendarClick}>
            <FontAwesomeIcon icon={faCalendarDays} className="sidebar-icon" />{" "}
            Calendar
          </button>
        </li>
        <li>
          <button onClick={() => resetView()}>
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="sidebar-icon"
            />{" "}
            Reports
          </button>
        </li>
      </ul>
    </nav>
    <button className="signout-button" onClick={handleSignOut}>
      <FontAwesomeIcon icon={faRightToBracket} className="sidebar-icon" /> Sign
      Out
    </button>
  </aside>
);

export default Sidebar;
