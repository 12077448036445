import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxArchive,
  faCalendarPlus,
  faFileSignature,
} from "@fortawesome/free-solid-svg-icons";
import ScheduleInspectionModal from "./ScheduleInspectionModal";

const ApplicationsTable = ({ applications, handleArchive, navigate, user }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleScheduleInspection = (application) => {
    setSelectedRow(application);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedRow(null);
  };

  const handleSaveInspection = (inspectionData) => {
    // You can add any logic here if needed after saving the inspection
    console.log("Inspection Scheduled:", inspectionData);
  };

  return (
    <>
      <table className="applications-table">
        <thead>
          <tr>
            <th>Date Submitted</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Move-In Date</th>
            <th>Lease Duration</th>
            <th>Occupation</th>
            <th>Pets</th>
            <th>Rented Before</th>
            <th>Residency Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {applications.map((application) => (
            <tr key={application.id}>
              <td>
                {new Date(application.dateSubmitted).toLocaleDateString()}
              </td>
              <td>{application.fullName}</td>
              <td>{application.email}</td>
              <td>{application.phoneNumber}</td>
              <td>{application.moveDate}</td>
              <td>{application.leaseDuration}</td>
              <td>{application.occupation}</td>
              <td>{application.pets}</td>
              <td>{application.rentedBefore}</td>
              <td>{application.residencyStatus}</td>
              <td>
                <div className="action-buttons">
                  <button
                    onClick={() => handleArchive(application.id)}
                    style={{ color: "white" }}
                  >
                    <FontAwesomeIcon icon={faBoxArchive} />
                  </button>
                  <button onClick={() => handleScheduleInspection(application)}>
                    <FontAwesomeIcon icon={faCalendarPlus} />
                  </button>
                  <button
                    onClick={() => navigate(`/application/${application.id}`)}
                  >
                    <FontAwesomeIcon icon={faFileSignature} />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedRow && (
        <ScheduleInspectionModal
          show={showModal}
          onClose={handleModalClose}
          onSave={handleSaveInspection} // Pass the onSave function here
          formData={selectedRow}
          user={user}
        />
      )}
    </>
  );
};

export default ApplicationsTable;
