import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const Task = ({ task, index, onDelete, onEdit }) => {
  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided) => (
        <div
          className="task"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className="task-content">
            <h3>{task.name}</h3>
            <p>{task.description}</p>
          </div>
          <div className="task-actions">
            <button onClick={() => onEdit(task)}>Edit</button>
            <button onClick={() => onDelete(task.id)}>Delete</button>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default Task;
