import React, { useEffect, useState, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

const Calendar = ({ handleDateClick, handleEventClick, handleNewEvent }) => {
  const [events, setEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView, setCurrentView] = useState("timeGridWeek");
  const calendarRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "Girilang/Calendar/Events"),
      (snapshot) => {
        const eventsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          title: doc.data().title,
          start: doc.data().start.toDate(),
          end: doc.data().end.toDate(),
          allDay: doc.data().allDay,
          backgroundColor: doc.data().colour,
          extendedProps: {
            description: doc.data().description,
            location: doc.data().location,
            status: doc.data().status,
            attendees: doc.data().attendees,
          },
        }));
        setEvents(eventsData);
      }
    );

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(currentView);
  }, [currentView]);

  const handlePreviousMonth = () => {
    const prevMonth = new Date(
      currentDate.setMonth(currentDate.getMonth() - 1)
    );
    setCurrentDate(prevMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(
      currentDate.setMonth(currentDate.getMonth() + 1)
    );
    setCurrentDate(nextMonth);
  };

  const handleViewChange = (event) => {
    setCurrentView(event.target.value);
  };

  const renderMonthNavigation = () => {
    const monthName = currentDate.toLocaleString("default", {
      month: "long",
      year: "numeric",
    });

    return (
      <div className="month-navigation">
        <FontAwesomeIcon
          icon={faChevronLeft}
          onClick={handlePreviousMonth}
          className="nav-icon"
        />
        <span className="month-name">{monthName}</span>
        <FontAwesomeIcon
          icon={faChevronRight}
          onClick={handleNextMonth}
          className="nav-icon"
        />
      </div>
    );
  };

  const renderDayGrid = () => {
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    const daysInMonth = [];
    for (let day = startOfMonth.getDate(); day <= endOfMonth.getDate(); day++) {
      daysInMonth.push(
        new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
      );
    }

    return (
      <div className="month-grid">
        {daysInMonth.map((date, index) => (
          <div
            key={index}
            className="month-grid-day"
            onClick={() =>
              handleDateClick({ dateStr: date.toISOString().split("T")[0] })
            }
          >
            {date.getDate()}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="calendar-wrapper">
      <div className="calendar-sidebar">
        <button className="new-event-button" onClick={handleNewEvent}>
          <FontAwesomeIcon icon={faPlus} className="new-event-icon" />
          New Event
        </button>
        {renderMonthNavigation()}
        {renderDayGrid()}
      </div>
      <div className="calendar-container">
        <div className="calendar-header">
          <div className="nav-controls">
            <button
              onClick={() => calendarRef.current.getApi().today()}
              className="fc-today-button fc-button fc-button-primary"
            >
              Today
            </button>
            <button
              onClick={() => calendarRef.current.getApi().prev()}
              className="fc-prev-button fc-button fc-button-primary"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button
              onClick={() => calendarRef.current.getApi().next()}
              className="fc-next-button fc-button fc-button-primary"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
          <div className="view-selector">
            <select
              id="view-select"
              value={currentView}
              onChange={handleViewChange}
            >
              <option value="dayGridMonth">Month</option>
              <option value="timeGridWeek">Week</option>
              <option value="timeGridDay">Day</option>
            </select>
          </div>
        </div>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={currentView}
          events={events}
          headerToolbar={false} // Disable the default header toolbar
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          editable={false}
          selectable={false}
          allDaySlot={false}
          slotMinTime="06:00:00"
          slotMaxTime="18:00:00"
          nowIndicator={true}
          slotMinWidth={250}
          dayHeaderContent={(args) => {
            const dayName = args.date.toLocaleDateString("en-US", {
              weekday: "short",
            });
            const dayNumber = args.date.getDate();
            return (
              <div className="fc-col-header-content">
                <div className="fc-day-name">{dayName}</div>
                <div className="fc-day-number">{dayNumber}</div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Calendar;
